<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-1-1">
                <div class="form-container">
                    <div class="row clearfix header-area">
                        <div class="col-1-2 sm-col-1-1">
                            <h1 class="page-title">{{chooseStore}}</h1>
                            <p>{{pleaseChooseTheStore}}</p>
                        </div>
                    </div>

                    <LoadingSpinner v-if="!storeSelection.length" />

                    <div v-else class="row clearfix selector-content">
                        <div class="col-1-2 sm-col-1-1">

                            <div class="search-block">
                                <input id="autocomplete" placeholder="City, zip code, street name..." v-model.trim="searchQuery" type="search" @input="searchStores()" class="pac-target-input" autocomplete="off">
                                <span id="getLocation"><font-awesome-icon icon="location-arrow" /></span>
                            </div>

                            <div id="geoError"></div>

                            <div v-if="storeSelection.length" class="storelist">
                                <button
                                    v-show="store.display"
                                    :ref="store.id" 
                                    class="storelist-button" 
                                    :class="{ active: store.selected}" 
                                    @click="selectStore(store.id)"
                                    v-for="store in storeSelection" :key="store.id">
                                    <div v-show="store.display">
                                        <span class="retailer">{{ store.name }}</span>
                                        <span class="address">{{ store.address }}, {{store.zipCode}}, {{ store.city }}</span>
                                        <span class="select">
                                            <font-awesome-icon icon="check" v-show="store.selected" />
                                        </span>
                                    </div>
                                </button>
                            </div>

                            <div 
                                @click="switchStoreSelection()" 
                                class="btn btn-secondary all-stores">
                                {{ showAllStores ? nearestStores : allStores }}
                            </div>
                        </div>

                        <div class="col-1-2 sm-col-1-1">
                            <GoogleMapLoader :stores="storeSelection" :userLocation="userLocation" :userLocLat="userLocation.lat" :userLocLng="userLocation.lng" :zoomLevel="zoomLevel" />
                        </div>
                    </div>

                    <div v-if="order" class="row clearfix">
                        <div v-if="order.store.name" class="col-1-1 form-row flex justify-center align-center prev-next-btns-wrapper">
                            <button @click="saveStore()" class="btn btn-primary alternative submitBtn">{{save}}</button>
                        </div>
                        <div v-else class="col-1-1 form-row flex justify-space-between align-center prev-next-btns-wrapper">
                            <router-link :to="`/place-reservation/summary?orderId=${orderId}`" class="btn btn-secondary">{{previousStep}}</router-link>
                            <button @click="saveStoreToOrder()" class="btn btn-primary alternative submitBtn">{{nextStep}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import GoogleMapLoader from '@/components/GoogleMapLoader';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
import agent from '../../api/agent';

export default {
    name: 'StoreSelector',
    components: { GoogleMapLoader, WarningMessageModal, LoadingSpinner },
    data() {
        return {
            showAllStores: true,
            selectedStore: null,
            storeSelection: [],
            searchQuery: '',
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
            userLocation: {
                lat: 0,
                lng: 0
            },
            zoomLevel: 7
        }
    },

    async created() {
        this.setDocumentTitle();

        await this.$store.dispatch('fetchStores');

        if (!this.order) {
            await this.$store.dispatch('fetchOrder', this.$route.query.orderId);
        }
        
        this.initLocation();

        this.setStoreSelection();

        if (this.order.store.name) {
            const id = this.$store.getters.storeByName(this.order.store.name).id;
            this.selectStore(id);
        }
    },

    updated() {
        this.setDocumentTitle();
    },
    
    computed: {
        ...mapGetters([
            'metaTitle',
            // Texts
            'chooseStore',
            'pleaseChooseTheStore',
            'allStores',
            'nearestStores',
            'previousStep',
            'nextStep',
            'isRequired',
            'notAllRequiredFieldsAreCompleted',
            'save'
        ]),
        ...mapGetters({
            stores: 'getStores',
            order: 'getOrder',
            orderId: 'getOrderId'
        }),
    },

    methods: {
        setDocumentTitle() {
            document.title = this.chooseStore + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        selectStore(id) {
            this.stores.forEach(store => {
                store.selected = false;
                if (store.id === id) {
                    store.selected = true;
                }
            });

            this.selectedStore = this.$store.getters.getStoreById(id);

            this.userLocation.lat = this.selectedStore.latitude;
            this.userLocation.lng = this.selectedStore.longitude;

            this.zoomLevel = 16;
        },

        getStoreDistances() {
            const rad = x =>  x * Math.PI / 180;
            const R = 6371; // radius of earth in km
            const distances = [];
                
            for (let i = 0; i < this.stores.length; i++) {
                const mlat = this.stores[i].latitude;
                const mlng = this.stores[i].longitude;
    
                const dLat  = rad(mlat - this.userLocation.lat);
                const dLong = rad(mlng - this.userLocation.lng);
    
                const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(this.userLocation.lat)) * Math.cos(rad(this.userLocation.lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                const d = R * c;
                distances[i] = {distance: d, storeId: this.stores[i].id};
            }

            return distances;
        },

        setStoreSelection() {
            const distances = this.getStoreDistances();
            if (this.showAllStores === false) {
                const nearestStores = [];
                distances.sort((a, b) => a.distance - b.distance);
    
                for (let i = 0; i < 5; i++) {
                    const nearestStore = this.stores.find(store => store.id === distances[i].storeId);
                    nearestStores.push(nearestStore);
                }
    
                this.storeSelection = nearestStores;
            } else {
                this.storeSelection = this.stores;
            }
        },

        initLocation() {
            const store = this.stores[0];
            this.userLocation.lat = store.latitude;
            this.userLocation.lng = store.longitude;

            if ('permissions' in navigator) {
                navigator.permissions.query({name: 'geolocation'})
                    .then(status => {
                        if (status.state === 'granted') {
                            navigator.geolocation.getCurrentPosition((position) => {   
                                this.userLocation.lat = position.coords.latitude;
                                this.userLocation.lng = position.coords.longitude;
                                this.switchStoreSelection();
                            });
                        }
                        status.addEventListener('change', () => {
                            this.switchStoreSelection();
                        });
                    });
            }
        },

        switchStoreSelection() {
            this.showAllStores = !this.showAllStores;
            this.setStoreSelection();
        },

        searchStores() {
            this.stores.forEach(store => {
                store.display = true;
                
                const queryIsPresentInCity = store.city.toLowerCase().includes(this.searchQuery.toLowerCase());
                const queryIsPresentInZip = store.zipCode.toLowerCase().includes(this.searchQuery.toLowerCase());
                const queryIsPresentInAddress = store.address.toLowerCase().includes(this.searchQuery.toLowerCase());
                const queryIsPresentInName = store.name.toLowerCase().includes(this.searchQuery.toLowerCase());
                
                if (!queryIsPresentInCity && !queryIsPresentInZip && !queryIsPresentInAddress && !queryIsPresentInName) {
                    store.display = false;
                }
            });
        },

        async saveStoreToOrder() {
            if (!this.selectedStore) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage =  this.chooseStore + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            const result = await agent.order.saveStore({
                orderId: this.$route.query.orderId,
                storeId: this.selectedStore.id
            });

            this.$router.push(`/register/step-1?orderId=${result}`);
        },

        async saveStore() {
            if (!this.selectedStore) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage =  this.chooseStore + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            const result = await agent.order.saveStore({
                orderId: this.$route.query.orderId,
                storeId: this.selectedStore.id
            });
            this.$store.dispatch('fetchOrder', result);
            this.$router.push(`/my-order/${result}`);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.form-container {
    .header-area {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $grey-light-7;
    }

    .page-title {
        text-align: left;
    }

    .selector-content {
        padding-top: 3rem;
    }

    #getLocation {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 7px;
    top: 50%;
    box-shadow: inset 0px 0px 0px 2px $color-primary;
    border-radius: 5px;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translateY(-50%);
    transition: all .2s ease;
}

    #getLocation:hover {
        background-color: $color-primary;
        color: $white;
    }

#geoError {
    display: none;
    border: 1px solid #a94442;
    border-radius: 4px;
    background-color: #f2dede;
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;
    color: #a94442;
}

input[type="search"]#autocomplete {
    background-color: #f5f6f7;
    border: 1px solid #d4dadf;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    margin: 0;
    font-weight: 300;
}

    input[type="search"]#autocomplete::placeholder {
        color: #d8d8d8;
        font-size: 1.8rem;
        line-height: normal;
        font-weight: normal;
    }

    .storelist {
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: 29rem;
}

    .storelist .storelist-button {
        background: $white;
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem 42px 1rem 1rem;
        border: 1px solid $white;
        border-bottom: 1px solid #d8d8d8;
        font-size: 1.6rem;
        line-height: normal;
        position: relative;
        cursor: pointer;
    }

        .storelist .storelist-button .select {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 22px;
            border-radius: 4px;
            border: 1px solid #d8d8d8;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

            .storelist .storelist-button .select i {
                opacity: 0;
                color: $white;
            }

        .storelist .storelist-button .retailer {
            display: block;
            font-weight: 700;
            color: $color-primary;
        }

        .storelist .storelist-button:hover,
        .storelist .storelist-button.active {
            background-color: rgba(248, 175, 45, 0.1);
        }

        .storelist .storelist-button.active {
            border: 1px solid $color-primary;
            border-radius: 4px;
        }

            .storelist .storelist-button.active .select {
                background-color: $color-primary;
                border: 1px solid $color-primary;
                color: $white;
            }

                .storelist .storelist-button.active .select i {
                    opacity: 1;
                }

        .storelist .storelist-button.hide {
            display: none;
        }

    .btn.all-stores {
        margin-top: 2rem;
        width: 100%;
        text-align: center;
    }

    .btn.all-stores.hide {
        display: none;
    }

    .search-block {
        position: relative;
        margin-bottom: 2rem;
    }

    .prev-next-btns-wrapper {
        margin-top: 5rem;

        a.btn {
            text-decoration: none;

            &:hover {
                color: $white;
            }
        }
    }
}

.storelist {
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-track {
        background-color: $grey-light-7;
    }
}

@media only screen and (max-width: 768px) {
    .form-container .btn.all-stores {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 500px) {
    .form-container .storelist span {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 400px) {
    .form-container {
        padding: 3rem 1rem;
    }
}

@media only screen and (max-width: 350px) {
    .form-container .storelist span {
        font-size: 1.2rem;
    }

    .form-container .prev-next-btns-wrapper a.btn {
        width: 100%;
        margin-bottom: 2rem;
        text-align: center;
    }
}
</style>